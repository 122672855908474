import React from 'react'

function Header() {
  return (
    <>
      <div className="header bg-gradient-warning pb-8 pt-5 pt-md-8"></div>
    </>
  )
}

export default Header
